.whiteBgUber {
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgba(166, 165, 165, 0.25);
    border-radius: 8px;
}

.whiteBgUber img {
    max-width: 150px;
    max-height: 110px;
}

.uberBlueBg {
    background: #50B8E7;
    border-radius: 8px 0px 0px 0px;
    color: #fff;
}

.uberBlueBg2 {
    background: #50B8E7;
    border-radius: 0px 8px 0px 0px;
    color: #fff;
}

.lightBlueBgUber {
    background: rgba(175, 212, 247, 0.22);
    border: 1px solid rgba(175, 212, 247, 0.22);
    border-radius: 4px;
}

.greyBorderBottomUber {
    border-bottom: 1px solid #DEDEDE;

}

.greyBorderRightUber {
    border-right: 1px solid #DEDEDE;
}

.lightBlueBgUber .row .col-6.text-start {
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.lightBlueBgUber .row .col {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.lightBlueBgUber .row svg {
    width: 26px;
    height: 20px;
}

.lightBlueBgUber .row .col.col-md-4 {
    display: flex;
    justify-content: center;
    align-items: center;

}

.uberUnsBg {
    background: url('../images/uberUns/uberUnsBg.svg') no-repeat;
    background-size: cover;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uberSignature {
    height: 110px;
    width: 180px;
}

@media (min-width: 1800.98px) {
    .uberUnsBg {
        height: 600px;

    }

    .krankenPramienBg {

        height: 800px;
    }
}

@media (min-width: 2200.98px) {
    .uberUnsBg {
        height: 700px;

    }

    .krankenPramienBg {

        height: 900px;
    }
}

@media (max-width: 1399.98px) {}

@media (max-width: 1199.98px) {
    .krankenPramienBg {
        background-position-x: -600px;
        height: 900px;
    }

    .darkTheme .krankenPramienBg {
        background-position-x: -600px;
        height: 900px;
    }
}

@media (max-width: 991.98px) {
    .uberSignature {
        max-width: 165px;
        height: 90px;
    }

    .krankenPramienBg {
        background-position-x: -900px;
        height: 1000px;
    }

    .darkTheme .krankenPramienBg {
        background-position-x: -900px;
        height: 1000px;
    }
    .uberUnsBg {
        background: transparent !important;
        height: auto !important;
    }
}

@media (max-width: 767.98px) {
    .krankenPramienBg {
        background: transparent !important;
        height: auto !important;
    }

    .darkTheme .krankenPramienBg {
        background: transparent !important;
        height: auto !important;
    }
    
}

@media (max-width: 575.98px) {
    .uberBlueBg {
        font-size: 14px;
    }

    .uberBlueBg2 {
        font-size: 14px;
    }

    .uberSignature {
        max-width: 100px;
    }

    .lightBlueBgUber span {
        font-size: 14px;
    }
}

.darkTheme .whiteBgUber {
    background-color: #333436;
    color: #fff;
    box-shadow: none;
}

.darkTheme .lightBlueBgUber {
    background-color: #333436;
    border: 1px solid #333436;
    color: #ffffff99;
}

.darkTheme .lightBlueBgUber .row svg path {
    stroke: #ffffff99 !important;
}

.darkTheme .uberUnsBg {
    background: url('../images/uberUns/uberUnsBgDark.svg') no-repeat;
    background-size: cover;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 1800.98px) {

    .darkTheme .uberUnsBg {
        height: 600px;

    }

    .darkTheme .krankenPramienBg {

        height: 800px;
    }
}

@media (min-width: 2200.98px) {

    .darkTheme .uberUnsBg {
        height: 700px;

    }

    .darkTheme .krankenPramienBg {

        height: 900px;
    }
}