.fw-400 {
    font-weight: 400 !important;
}

.containerRes input {
    border: 4px solid #fff;
}

.checkmarkRes {
    border: 1px solid #fff;
    border-radius: 12px;
    padding: 1rem;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    cursor: pointer;
}



.containerRes input:checked~.checkmarkRes {
    border: 1px solid #2196F3;
    transition: all 0.2s ease-in-out;
}

.checkmarkRes2 {
    display: block;
}

.containerRes2 input:checked~.checkmarkRes2:after {
    display: block;
}

.containerRes2 input {
    border: 3px solid #fff;
}

.checkmarkRes2 {
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    cursor: pointer;
    padding: 0.25rem 0rem 0.25rem 0rem;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
}



.containerRes2 input:checked~.checkmarkRes2 {
    border: 1px solid #2196F3;
    transition: all 0.2s ease-in-out;
}

.checkmarkRes2 {
    display: block;
}

.containerRes2 input:checked~.checkmarkRes2:after {
    display: block;
}

.containerResSteps {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.containerResSteps input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmarkResSteps {
    position: absolute;
    top: -15px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.containerResSteps:hover input~.checkmarkResSteps {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerResSteps input:checked~.checkmarkResSteps {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkResSteps:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerResSteps input:checked~.checkmarkResSteps:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.containerResSteps .checkmarkResSteps:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.checkmarkRes2 img {
    width: 25px;
}

.genderRadioBox {
    padding: 0.82rem;
    border-radius: 11px;
}

.underCheckmarkSpan {
    font-weight: 400 !important;
}

.step3InputRechtss {
    width: 100%;
    background-color: #fff !important;
    border: none !important;
    border-radius: 8px !important;
    padding: 0.5rem !important;
    outline: none !important;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
    -moz-box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
    -webkit-box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
}

/* .step3InputRechtss::placeholder {
    color: #AAABAB !important;
    font-weight: 500 !important;
} */

.step3RechtssBtn {
    background: #358ACA;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-weight: 500;
    padding: 0.75rem;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
}

.blueBgRechtss {
    background: url('../images/rechtsschutzImgs/blueBg.svg') no-repeat;
    background-size: cover;
    height: 552px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position-y: 0px;
}

.whiteBgRecht {
    background-color: #fff;
    border-radius: 15px;
    position: relative;
    border: 2px solid rgba(0, 0, 0, 0.24);

}

.blueBgRecht {
    background: #34ACE3;
    border-radius: 15px;
    color: #fff;
    position: absolute;
    width: calc(100% - 50px);
    left: 25px;
    top: -32px;
    border: 4px solid #34ACE3;
    ;
}

.rechtssBtn {
    border-radius: 15px;
    background: #34ACE3;
    border: none;
    color: #fff;
    width: 100%;
    font-weight: 600;
}

.absoluteDivRecht {
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
    width: 150px;
    position: absolute;
    left: calc(50% - 75px);
    top: -3rem;
}

.whiteBorderBottomRecht {
    border-bottom: 1px solid #fff;
    color: #fff;
    text-align: start;
    cursor: pointer;
}

.whiteBorderBottomRecht span {
    font-weight: 400;
}

.faqClosed {
    height: 0px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.faqOpen {
    height: 80px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
}

.faqOpen3 {
    height: 110px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
}

.faqOpen2 {
    height: 130px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
}

.faqOpen1 {
    height: 200px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
}

ol, ul {
    padding-left: 1rem !important;
}

.blueBgRechtssPriv {
    background-color: #fff;
}

.rechStepsBorderDiv {
    border: 2px solid #6FB6E2;
    border-radius: 20px;
    position: relative;
}

.rechStepNrDiv {
    position: absolute;
    top: 3rem;
    right: 3rem;
}

.rechInputStyle2 {
    border: 1px solid #ddd !important;
    box-shadow: none !important;
    border-radius: 11px !important;
    padding: 1rem 0.5rem !important;
}

.react-datepicker__navigation {
    top: 15px !important;
}

.datePadding {
    padding: 5px !important;
}

.PhoneInputCountryIconImg {
    display: none !important;
}

.datePlaceholderRech::placeholder {
    color: #8E8E8E60 !important;
}

.datePlaceholderRech {
    padding-left: calc(0.5rem + 10px) !important;
}

.labelStyleRech span {
    color: #8E8E8E60 !important;
    background-color: #fff;
    padding: 0px 5px;
}

.labelStyleRech {
    opacity: 1 !important;
}

.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    top: -17px !important;
    color: #00000080;
    font-weight: 500;
}

.form-floating>.form-control:not(:placeholder-shown)~label span {
    color: #00000080 !important;
    font-weight: 500 !important;
}

.form-control:focus~label span {
    color: #00000080 !important;
    font-weight: 500 !important;
}

.greyCheckMarkBorder {
    border-color: #ddd;
    box-shadow: none !important;
}

.containerRes2 input:checked~.greyCheckMarkBorder {
    border-color: #50B8E7 !important;
}

.containerRes input:checked~.greyCheckMarkBorder {
    border-color: #50B8E7 !important;
}

.submitErrorInputStyle {
    border-color: #E76250 !important;
}

.PhoneInputInput {
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
}

.PhoneInputInput::placeholder {
    color: #8E8E8E60 !important;
}

.dateLabelRech {
    position: absolute;
    top: -4px;
    left: 2px;
    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);

}

.dateLabelRech span {
    color: #00000080 !important;
    font-weight: 500 !important;
}

@media (max-width: 1399.98px) {}

@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {
    .faqOpen {
        height: 110px;
    }

    .faqOpen3 {
        height: 140px;
    }

    .rechStepNrDiv {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        line-height: 1;
    }

    .rechInputStyle2::placeholder {
        font-size: 15px;
    }
}

@media (max-width: 767.98px) {
    .faqOpen3 {
        height: 180px;
    }

    .faqOpen2 {
        height: 160px;
    }

    .faqOpen1 {
        height: 250px;
    }

    .faqOpen {
        height: 130px;
    }

    .rechStepNrDiv {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
    }
}

@media (max-width: 575.98px) {
    .absoluteDivRecht {
        top: -2.5rem;
    }

    .faqOpen1 {
        height: 370px;
    }

    .faqOpen2 {
        height: 250px;
    }

    .faqOpen3 {
        height: 300px;
    }

    .faqOpen {
        height: 200px;
    }


    .rechInputStyle2::placeholder {
        font-size: 14px;
    }

    .datePlaceholderRech::placeholder {
        font-size: 14px;
    }

    .PhoneInputInput::placeholder {
        font-size: 14px !important;
    }

    .labelStyleRech {
        font-size: 14px;
        line-height: 1.7 !important;
    }
}

@media (max-width: 391.98px) {
    .faqOpen2 {
        height: 270px;
    }

    .faqOpen {
        height: 250px;
    }

    .faqOpen1 {
        height: 450px;
    }
}

@media (max-width: 360.98px) {
    .whiteBorderBottomRecht span {
        font-size: 16px !important;
    }

    .faqOpen3 {
        height: 360px;
    }

    .faqOpen1 {
        height: 500px;
    }
}

@media (max-width: 320.98px) {
    .faqOpen3, .faqOpen1, .faqOpen2 {
        height: 100%;
        margin-bottom: 1rem;
    }

    .faqOpen {
        height: 300px;
    }

    .faqOpen1 {
        height: 530px;
    }

}

.darkTheme .hausratBlueDiv {
    background-color: #333436;
}

.darkTheme .blueBgRechtss {
    background: url('../images/rechtsschutzImgs/blueBgDark.svg') no-repeat;
    background-size: cover;
    height: 552px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position-y: 0px;

}

.darkTheme .blueBgRechtssPriv {
    background-color: #282424;
}

.darkTheme .blueBgRecht {
    background-color: #282424;
    box-shadow: rgb(0 0 0 / 30%) 0 0 10px !important;
    border: 1px solid #50B8E7;
}

.darkTheme .whiteBgRecht {
    background-color: #282424;
    color: #fff;
}

.darkTheme .step3InputRechtss {
    background-color: #282424 !important;
    color: #fff;
}

/* .darkTheme .step3InputRechtss::placeholder {
    color: #AAABAB !important;
    font-weight: 500 !important;
} */
.darkTheme .datePlaceholderRech::placeholder {
    color: #AAABAB !important;
}

.darkTheme .form-control:focus {
    color: #fff !important;
}

.darkTheme .PhoneInputInput {
    color: #fff;
}

.darkTheme .PhoneInputInput::placeholder {
    color: #AAABAB !important;

}

.darkTheme .rechInputStyle2 {
    border: 1px solid #434343 !important;
}

.darkTheme .labelStyleRech span {
    color: #AAABAB !important;
    background-color: #282424;
    padding: 0px 5px;

}

.darkTheme .form-control:focus~label span {
    color: #AAABAB !important;
    font-weight: 500 !important;
}
.darkTheme .form-floating>.form-control:not(:placeholder-shown)~label span {
    color: #AAABAB !important;
    font-weight: 500 !important;
}