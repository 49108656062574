.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.loaderStyle {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
  left: calc(50% - 50px);
  margin-top: -8rem;
}

body {
  padding-top: 8rem;

}


.hiddenFormSupport {
  height: 0px;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.darkTheme .transparentInput {
  background-color: transparent !important;
  border: 1px solid #434343 !important;
}

.finanuAppWrapper {
  border-radius: 25px;
  background-color: #50B8E7;
  color: #fff;
  text-align: left !important;
}

.finanuAppWrapper a {
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.whiteCardKranken a {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  color:#212529;
}
.darkTheme .whiteCardKranken a {
  color:#fff;
}
.supportFile p {
  border: 1px solid #34ACE3;
  background-color: transparent;
  border-radius: 8px;
  cursor: pointer;
}

.supportModalTitle {
  color: #34ACE3;
  font-weight: 600;
  font-size: 20px;
}
.cancelBtn {
  border: 1px solid #4F4F4F !important;
  background-color: transparent !important;
  color: #4F4F4F !important;
} 


@media (max-width: 767.98px) {
  .loaderStyle {
    margin-top: -6rem;

  }

  body {
    padding-top: 6rem;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}