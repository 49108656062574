.stepsBlueDiv {
    background: #84CDEE;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 8px;
}

.stepsBlueDiv span {
    color: #fff;
}

.stepsFormInputs {
    background-color: #fff;
    border-radius: 11px !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0.75rem !important;
}

.stepsNextBtn {
    background: #11A3E6;
    border-radius: 11px;
    border: none;
    width: 100%;
    color: #fff;
    font-weight: 700;
    padding: 0.75rem;
}

.activeBackground {
    background: #11A3E6;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.passiveBackground {
    background: #fff;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.activeSvg {
    width: 25px;
}

.passiveSvg {
    width: 25px;

}

.activeSvg path {
    stroke: white !important;
    margin: auto;
}

.activeSvg rect {
    fill: #11A3E6;
}

.passiveSvg path {
    stroke: #D0D0D0;
    margin: auto;
}

.borderRightInput {
    border: none;
    border-right: 1px solid #DBDBDB;
    padding: 0.75rem !important;
    border-radius: 11px 0px 0px 11px;
    outline: none !important;
}

.rightBorderDiv {
    padding: 0% !important;
}

.steps360 .krankenInputStyle::placeholder {
    font-weight: 400 !important;
    color: #434343 !important;
}
.yahrMonatBtn {
    width: 50px;
}
@media (max-width: 1399.98px) {
    .passiveBackground {
        width: 45px !important;
        height: 45px !important;
    }

    .activeBackground {
        width: 45px !important;
        height: 45px !important;
    }

    .activeSvg {
        width: 25px !important;
    }

    .passiveSvg {
        width: 25px !important;
    }

    .stepsSvgRow .col-auto.my-auto svg {
        width: 25px !important;
    }
}

@media (max-width: 1199.98px) {
    .passiveBackground {
        width: 40px !important;
        height: 40px !important;
    }

    .activeBackground {
        width: 40px !important;
        height: 40px !important;
    }

    .activeSvg {
        width: 20px !important;
    }

    .passiveSvg {
        width: 20px !important;
    }

    .stepsSvgRow .col-auto.my-auto svg {
        width: 20px !important;
    }
}

@media (max-width: 991.98px) {
    .passiveBackground {
        width: 35px !important;
        height: 35px !important;
    }

    .activeBackground {
        width: 35px !important;
        height: 35px !important;
    }

    .activeSvg {
        width: 20px !important;
    }

    .passiveSvg {
        width: 20px !important;
    }

    .stepsSvgRow .col-auto.my-auto svg {
        width: 15px !important;
    }
}

@media (max-width: 767.98px) {
    .passiveBackground {
        width: 50px !important;
        height: 50px !important;
    }

    .activeBackground {
        width: 50px !important;
        height: 50px !important;
    }

    .activeSvg {
        width: 20px !important;
    }

    .passiveSvg {
        width: 20px !important;
    }

    .stepsSvgRow .col-auto.my-auto svg {
        width: 10px !important;
    }
}

@media (max-width: 575.98px) {
    .passiveBackground {
        width: 35px !important;
        height: 35px !important;
    }

    .activeBackground {
        width: 35px !important;
        height: 35px !important;
    }

    .activeSvg {
        width: 18px !important;
    }

    .passiveSvg {
        width: 18px !important;
    }

    .stepsSvgRow .col-auto.my-auto svg {
        width: 7px !important;
    }

    .steps360 .krankenInputStyle::placeholder {
        font-size: 14px;
    }
}

.darkTheme .stepsBlueDiv {
    background-color: #333436;
}

.darkTheme .stepsBlueDiv input {
    background-color: #282424 !important;
    border: none !important;
    color: #fff;

}
.darkTheme .stepsBlueDiv .PhoneInput {
    background-color: #282424 !important;
    border: none !important;
    color: #fff;

}
.darkTheme .stepsBlueDiv input::placeholder {
    color: #fff;
}

.darkTheme .krankenInputStyle::placeholder {
    color: #fff !important;
}

.darkTheme .stepsBlueDiv select {
    background-color: #282424 !important;
    border: none !important;
    color: #fff;
}

.darkTheme .stepsBlueDiv select:disabled {
    background-color: #282424 !important;
}

.darkTheme .stepsBlueDiv option {
    background-color: #282424 !important;
    color: #fff;
}

.darkTheme .rightBorderDiv {
    background-color: #282424 !important;
    border-right: 1px solid transparent !important;
}

.darkTheme .rightBorderDiv span {
    color: #fff !important;
}

.darkTheme .rightBorderDiv .col {
    border-right: 1px solid #ffffff50;
}

.darkTheme .passiveBackground {
    background-color: #333436;
}

.darkTheme .passiveSvg rect {
    fill: transparent;
}
