@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&family=Work+Sans:wght@400;500;600;700;800&display=swap');

body {
    font-family: 'Raleway';
}

div {
    font-family: 'Raleway';
}

span {
    font-family: 'Raleway';
}

p {
    font-family: 'Raleway';
}

a {
    font-family: 'Raleway';
}

.krankenFirstStep {
    background-color: #50B8E7;
    border-radius: 12px;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
}

.krankenTitle {
    font-weight: bold;
    color: #fff;
}

.krankenSubtitle {
    font-weight: 500;
    color: #fff;
    font-size: 16px;
}

.krankenInputStyle {
    width: 100%;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid transparent;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
    outline: none !important;
    padding: 0.75rem;
}

.dateStyleIOS {
    width: 100% !important;
    border-radius: 12px !important;
    background-color: #fff !important;
    border: none !important;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
    outline: none !important;
    padding: 0.75rem !important;
}

/* .krankenInputStyle::placeholder {
    font-weight: 500;
    color: #000;
    opacity: 0.8;
} */

.krankenSelectStyle {
    width: 100%;
    border-radius: 12px !important;
    background-color: #fff !important;
    border: none !important;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
    outline: none !important;
    padding: 0.75rem !important;
    background-image: url('../../assets/images/krankenArrow.svg') !important;
    font-weight: 500 !important;
}

.krankenBtnStyle {
    width: 100%;
    padding: 0.75rem;
    border-radius: 12px;
    border: none;
    background-color: #fff;
    color: #fff;
    background-color: #11A3E6;
    font-weight: 500;

}

.blueBgKranken {
    background: url('../../assets/images/krankenBlueBg.svg') no-repeat;
    height: 1455px;
    width: 100%;
    overflow: hidden !important;
    background-size: cover;
    background-position-x: -200px;
    background-position-y: -200px;

}

.blueBgKranken1 {
    background: url('../../assets/images/krankenBlueBg.svg') no-repeat;
    width: 100%;
    overflow: hidden !important;
    background-size: cover;
    background-position-x: -200px;
}

.blueBgKrankenOverflow {
    overflow: hidden !important;
}

.blueBgCenterText {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.whiteBgKranken {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
    color: #50B8E7;
    width: 400px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 1rem 0rem;
    position: absolute;
    left: calc(50% - 190px);
    top: -50px;
}

.bigTitleKranken {
    font-weight: bold;
    color: #50B8E7;
}

.descriptionFS {
    font-size: 18px;
}

.whiteCardKranken {
    border-radius: 12px;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px;
    text-align: center;
}

.whiteCardKranken img {
    height: 95px;
}

.privatenImgSize {
    height: 100%;
}

.darkTheme .titleoffolgende {
    color: white;
}

.privatenImgSize img {
    height: 150px;
}

.changeJustifyTablet {
    justify-content: space-between;
}

.zuzatImages img {
    height: 95px;
    max-width: 120px;
}

.grundImages img {
    width: 100%;
}

.krankenSelectDropdown {
    position: absolute;
    top: 3.5rem;
    background-color: #fff;
    max-height: 300px;
    padding: 0.75rem;
    width: 100%;
    border-radius: 12px;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
    overflow-y: auto;
    z-index: 100;
    text-align: left;
}

.selectOptionStyle {
    padding-bottom: 0.5rem;
    cursor: pointer;
}

.selectOptionStyle:last-child {
    padding: 0%;
}



.circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: stroke 5s ease-out forwards;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }

  to {
    stroke: #27B0Ed
  }
}

.container1 input {
    border: 1px solid #ddd;
}

.checkmark {
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 1rem;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    cursor: pointer;
}


.container1 input:checked~.checkmark {
    border: 1px solid #50B8E7;
    transition: all 0.2s ease-in-out;
}

.checkmark {
    display: block;
}

.container1 input:checked~.checkmark:after {
    display: block;
}

.checkmarkColorAuto {
    border: 4px solid #fff;
}



.container1 input:checked~.checkmarkColorAuto {
    border: 4px solid #2196F3;
    transition: all 0.2s ease-in-out;
}

.nextBtnKranken {
    border: none;
    background-color: #50B8E7;
    border-radius: 12px;
    padding: 0.75rem;
    width: 200px;
    color: #fff;
    font-weight: 600;
}

.nextBtnKranken2 {
    width: 100%;
    border-radius: 3px;
    padding: 1rem 0rem;
}

.krankenStepsBox {
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;
    border-radius: 12px;
    padding: 3rem;
}

.krankenInputStepStyle {
    box-shadow: none !important;
    border: 1px solid #ddd !important;
    border-radius: 12px !important;
    font-weight: 500 !important;
}

.plusPersonBtn {
    font-size: 18px;
    color: #11A3E6;
    cursor: pointer;
}

.krankenOfferStyle {
    background: #FFFFFF;
    box-shadow: 0px 4px 9px 4px rgba(172, 172, 172, 0.25);
    width: 320px;
}

.greyBorderDiv {
    border: 2px solid rgba(0, 0, 0, 0.24);
    border-radius: 18px;
    height: 100%;
    transition: all 0.2s ease-in-out;

}

.blueBgDiv {
    background: #50B8E7;
    border-radius: 18px;
    color: #fff;
    border: 1px solid #50B8E7;
    transform: scale(1.03);
    transition: transform 0.3s ease-in-out;
}

.blueBgDiv path {
    stroke: #fff;
}

.blueBgDiv svg {
    fill: #50B8E7;
}

.blueBgDiv .row svg path {
    fill: #fff;
}

.mehrLadenModal {
    background: rgba(29, 32, 34, 0.4);
    backdrop-filter: blur(15px);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.mehrLadenModalContent {
    background: #fff;
    border-radius: 23px;
    width: 800px;
}

.lastIlustrationKranken {
    max-width: 500px !important;
}

.blueBorderBottom {
    border-bottom: 2px solid #50B8E7;
}

.blueBorderBottom .cardTitle {
    color: #50B8E7;
}

.whiteBorderBottom {
    border-bottom: 2px solid #fff;

}

.whiteBorderBottom .cardTitle {
    color: #fff;
}

.cornerOffer {
    position: absolute;
    right: 0px;
    top: 0px;
}

.starsImg {
    width: 150px;
}



.p-4.whiteCardKranken.h-100.text-center img {
    width: 100px;
}

.gutZuWissenDiv {
    max-width: 1100px !important;
}

.krankenPramienBg {
    background: url('../images/kraken/pramienBg.svg') no-repeat;
    background-size: cover;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkmarkBoxShadow {
    border: 1px solid #fff;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px;
    border-radius: 9px;
}

.krankenBoxShadowInput {
    border: 1px solid #fff !important;
    box-shadow: rgb(0 0 0 / 8%) 0 0 10px !important;

}

@media (min-width: 1700px) {
    .blueBgKranken {
        background-position-x: -190px;

    }
}

@media (max-width: 1399.98px) {
    .krankenSubtitle {
        font-size: 16px;
    }

}

@media (max-width: 1199.98px) {
    .krankenOfferStyle {
        width: 290px;
    }
}


@media (max-width: 991.98px) {
    .blueBgKranken {
        height: auto;
        margin-top: 40px;
        padding-bottom: 3rem;
    }



    .blueBgKranken1 {
        height: auto;
    }

    .krankenOfferStyle {
        width: 100%;
    }



    .emptyOption {
        font-size: 14px !important;
    }
}

@media (max-width: 767.98px) {
    .blueBgKranken {
        height: auto;
        margin-top: 0px;
    }



    .blueBgKranken1 {
        margin-top: 0px;
    }

    .changeJustifyTablet {
        justify-content: center;
    }

    .grundImages img {
        width: 150px;

    }

    .greyBorderDiv {
        height: 100%;
    }

    .krankenStepsBox {
        padding: 1.5rem;
    }

    .krankenSubtitle {
        font-size: 16px;
    }

}

@media (max-width: 575.98px) {
    .whiteBgKranken {
        width: 240px;
        left: calc(50% - 120px);
    }



    .mehrLadenModalContent {
        width: auto;
        border-radius: 23px;
        height: 80vh;
        overflow-y: auto;
    }

    .grundImages img {
        width: 100%;

    }

    .lastIlustrationKranken {
        width: 100%;
    }

    .krankenOfferStyle {
        max-width: 350px;
    }

    .starsImg {
        width: 120px;
    }

    .krankenSubtitle {
        font-size: 14px;
    }

    .nextBtnKranken {
        width: 100%;
    }

    .autoBtnStyle {
        width: 100%;

    }

    .emptyOption {
        font-size: 12px !important;
    }
}

.darkTheme .krankenFirstStep {
    background-color: #333436;
    color: #fff;
}

.darkTheme .krankenFirstStep input {
    background-color: transparent !important;
    border: 1px solid #11A3E6 !important;
    color: #fff;

}

/* .darkTheme .krankenFirstStep input::placeholder {
    color: #fff;
} */

.darkTheme .krankenSelectDropdown {
    background-color: #282424;
    color: #fff;
}

.darkTheme .krankenFirstStep select {
    background-color: transparent !important;
    border: 1px solid #11A3E6 !important;
    color: #fff;
}

.darkTheme .krankenFirstStep option {
    background-color: #282424 !important;
    border: 1px solid #11A3E6 !important;
    color: #fff;
}

.darkTheme .krankenStepsBox {
    color: #fff;
    background-color: #333436;
}

.darkTheme .krankenStepsBox input {
    background-color: transparent !important;
    border: 1px solid #fff !important;
    color: #fff;

}

/* .darkTheme .krankenStepsBox input::placeholder {
    color: #fff;
} */

.darkTheme .krankenStepsBox select {
    background-color: transparent !important;
    border: 1px solid #fff !important;
    color: #fff;
}

.darkTheme .krankenStepsBox select:disabled {
    background-color: #282424 !important;
}

.darkTheme .krankenStepsBox option {
    background-color: #282424 !important;
    color: #fff;
}

.darkTheme .krankenInputStepStyle {
    background-color: #282424 !important;
    border: 1px solid #434343 !important;
    color: #fff !important;
}
.darkTheme .krankenBoxShadowInput {
    border: 1px solid #fff !important;

}
.darkTheme .krankenInputStepStyle option {
    background-color: #282424 !important;
    border: 1px solid #11A3E6 !important;
    color: #fff;
}

.darkTheme .checkmark {
    color: #fff;
}

.darkTheme .krankenOfferStyle {
    background-color: #333436;
    color: #fff;
    box-shadow: none;
}

.darkTheme .krankenOfferStyle hr {
    background-color: #50B8E7 !important;
}

.darkTheme .mehrLadenModalContent {
    background-color: #333436;
    color: #fff;
}

.darkTheme .mehrLadenModalContent span {
    color: #fff !important;

}

.darkTheme .mehrLadenModalContent input {
    border: 1px solid #434343 !important;
}

.darkTheme .mehrLadenModalContent select {
    border: 1px solid #434343 !important;
}

.darkTheme .whiteBgKranken {
    background-color: #333436;
}

.darkTheme .whiteCardKranken {
    background-color: #333436;
    color: #fff;
}

.darkTheme .finanuSubTitle {
    color: #fff !important;
}

.darkTheme .greyBorderDiv {
    color: #fff;
    border: 2px solid #50B8E7;
}

.darkTheme .blueBgKranken {
    background: url('../../assets/images/krankenBlueBgDark.svg') no-repeat;
    width: 100%;
    overflow: hidden !important;
    background-size: cover;
    margin-top: -200px;
}

.darkTheme .krankenInputStepStyle.krankenInputStyle.p-2 svg path {
    fill: #fff;
}

.darkTheme .krankenGiftSvg path {
    fill: white;
}

.darkTheme .krankenPramienBg {
    background: url('../images/kraken/pramienBgDark.svg') no-repeat;
    background-size: cover;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 1700px) {
    .darkTheme .blueBgKranken {
        background-position-x: -190px;
    }
}

@media (max-width: 991.98px) {
    .darkTheme .blueBgKranken {
        height: 1000px;


    }

    .darkTheme .blueBgKranken1 {
        height: auto;
        margin-top: 50px;
    }
}

@media (max-width: 767.98px) {
    .darkTheme .blueBgKranken {
        height: auto;
        margin-top: 0px;
    }

    .darkTheme .blueBgKranken1 {
        margin-top: 0px;
    }


}