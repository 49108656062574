@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&family=Work+Sans:wght@400;500;600;700;800&display=swap');

body {
    font-family: 'Raleway';
}

div {
    font-family: 'Raleway';
}

span {
    font-family: 'Raleway';
}

p {
    font-family: 'Raleway';
}

a {
    font-family: 'Raleway';
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.pensionPlanBlueBg {
    background-image: url('../../assets/images/pensionPlanBlueBg.svg') !important;
    background-size: cover;
}

.pensionChartDiv {
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(187, 185, 185, 0.25);
    border-radius: 16px;
}

.pensionChartTitle {
    font-weight: 700;
    color: #11A3E6;
}

.pensionWhiteCards {
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgba(166, 165, 165, 0.25);
    border-radius: 8px;
}

.pensionWhiteCards img {
    width: 150px;
    height: 140px;
}

.blueBgDivPension {
    background: #50B8E7;
    border-radius: 16px;
}
.pensionBlueDivWidth {
    max-width: 850px;
    margin: auto;
}
.whiteBgInBluePension {
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgba(166, 165, 165, 0.25);
    border-radius: 8px;
    width: 500px;
    margin: auto;
    margin-top: -5rem;
}

.pensionBankBorderBottom {
    border-bottom: 1px solid #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.apexcharts-xaxistooltip {
    background: transparent !important;
    border: 1px solid transparent !important;
}

.apexcharts-legend-marker {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 20px;
    border-style: solid;
    height: 35px !important;
    margin-top: 0px;
    margin-bottom: 15px;
}

.apexcharts-legend-text {
    margin-top: -10px;
}

.apexcharts-legend {
    justify-content: center !important;
}

.apexcharts-tooltip {
    display: none !important;
}

.positionAbsoluteToolTip {
    position: absolute;
    top: 0.5rem;
}

@media (max-width: 1399.98px) {}

@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {
    .whiteBgInBluePension {
        margin-top: -3.5rem;
    }
    .positionAbsoluteToolTip {
        top: 2rem;
    }
    
}

@media (max-width: 575.98px) {
    .whiteBgInBluePension {
        width: 100%;
    }

    .apexcharts-legend {
        justify-content: start !important;
    }
    .positionAbsoluteToolTip {
        display: none;
    }
    .hideOnMob {
        display: none;
    }
    .pensionBankBorderBottom  {
        height: 100px;
        justify-content: start;
    }
}

.darkTheme .pensionChartDiv {
    background-color: #333436;
    color: #fff;
    box-shadow: none;
}
.darkTheme .inChartDiv {
    background-color: #282828;

}
.darkTheme .pensionWhiteCards {
    background-color: #333436;
    color: #fff;
    box-shadow: 0px 4px 17px rgb(000 000 000 / 25%);
}

.darkTheme .whiteBgInBluePension {
    background-color: #333436;
    box-shadow: 0px 4px 17px rgba(000, 000, 000, 0.25);

}

.darkTheme .blueBgDivPension {
    background-color: #333436;
}

.darkTheme .pensionPlanBlueBg {
    background-image: url('../../assets/images/pensionPlanBlueBgDark.svg') !important;
}