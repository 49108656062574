.versicherungenLinks {
    background: #fff;
    box-shadow: 0px 4px 18px rgba(170, 170, 170, 0.25);
    border-radius: 16px;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    padding: 50px 31px;
}

.versicherungenLinks svg,
.versicherungenLinks img {
    max-width: 250px;
    height: 70px;
}

/* <This is the begining of Groupe Mutuel CSS */
.backgroundimageofgroupemuetel {
    background: url(../images/versicherungen/groupemutuel.png);
    height: 311px;
    background-repeat: no-repeat;
    width: 100%;
    background-position-x: 100%;
}

.buttonofgroupemutuel {
    background: #6C8B97;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    border: none;
}

.subtitleofservicees {
    font-weight: 600;
    font-size: 14px;
    line-height: 127.4%;
    /* identical to box height, or 18px */

    display: flex;
    justify-content: center;
    letter-spacing: -0.01em;

    /* Secondary */

    color: #333333;

}

.mainrowofgrupemutuel {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.09);
    border-radius: 19px;

}

.servicesofgroupe {

    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    justify-content: center;

}

.maindivofcard {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.09);
    border-radius: 19px;
    height: 100%;
}

.darkTheme .maindivofcard {
    background: #333436;
    color: #fff;

}

.darkTheme .titleofcards {
    color: #fff;
}

.darkTheme .testofcards {
    color: #fff;
    
}
.darkTheme .listofcards {
    color: #fff;

}
    
    .imagesofcard {
        width: 100%;
        height: 205px;
    }

    .testofcards {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 127.4%;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;

        color: #183C4A;
    }

    .listofcards {
        font-weight: 400;
        font-size: 14px;
        line-height: 127.4%;
        flex-direction: column;
        display: flex;
        letter-spacing: -0.01em;

        color: #183C4A;
    }

    .titleofcards {
        font-weight: 800;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #E5AB3B;

    }

    /* This is the end />  */
    .servicesBorderDiv {
        box-shadow: 0px 4px 17px rgb(166 165 165 / 25%);
        text-align: center;
        height: 100%;
        color: #000;
        border-radius: 1rem;
    }

    .svgPurpleCircle {
        border: 3px solid #8F85BA;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .servicesBorderDivSwica {
        background: #FFFFFF;
        box-shadow: 0px 4px 15px rgba(119, 119, 119, 0.25);
        border-radius: 4px;
        height: 100%;
    }

    .photoBorderDiv {
        border: 9px solid #891250;
    }

    .sympanyPurpleSpan {
        color: #8F85BA;

    }

    .swicaBackground {
        /* background: url('../images/versicherungen/swicaBg.png') no-repeat; */
        background-size: cover;
        height: 75vh;
        width: 100%;
    }

    .positionAbsoluteVid {
        position: absolute;
        z-index: -1;
        height: auto;
        width: 100%;
        overflow: hidden;
        padding-right: 10rem;
    }

    .positionAbsoluteVid video {
        height: 75vh;
        min-width: 100%;
        min-height: 100%;
        overflow: hidden;
    }

    .swicaLogoDiv {
        position: absolute;
        top: 7rem;
        left: calc(50% - 143.5px);
    }

    .swicaLogoDiv img {
        width: 290px;
    }

    .swicaBtn {
        background: #179F96;
        border: none;
        padding: 15px 0px;
        border-radius: 4px;
        width: 174px;
        font-weight: 600;
        letter-spacing: -0.005em;
        line-height: 19px;
        color: #FFFFFF;
    }

    .normalTextToWhite {
        color: #000;
    }

    .borderTopAcc {

        border: none;
        border-top: 1px solid #eee;

    }

    .borderTopAccSympany {
        border: none;
        border-top: 1px solid #ebd7c1;
        background-color: transparent !important;
    }

    .sympanyAccItem .accordion-button {
        background-color: transparent !important;
    }

    .sympanyAccItem .accordion-button:not(.collapsed) {
        background-color: transparent !important;

    }

    .accordion-button:not(.collapsed) {
        box-shadow: none !important;
    }

    .accordion-button:focus {
        box-shadow: none !important;

    }

    .swc-text--has-background {
        background-color: #f2f2f2;
        padding: 1.5rem 1.125rem;
    }

    a.rte-button {
        align-items: center;
        background: #179f96;
        border: 0.125rem solid #179f96;
        color: #fff;
        cursor: pointer;
        display: inline-flex;
        font-family: Futura W01 Book, Helvetica Neue, Arial, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        margin: 0 0 5px;
        min-height: 3.2rem;
        outline: none;
        padding: 0 1.5rem;
        text-align: center;
        text-decoration: none;
        transition: all .3s;
    }

    ul.arrow li:before {
        background: none;
        background-image: url('../images/versicherungen/swicaArrow.svg');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 1rem;
        height: 1rem;
        top: 0.25rem;
        width: 1rem;
    }

    .swicaLinks {
        color: #891350;
        text-decoration: none;
    }

    .swicaLinks:hover {
        cursor: pointer;
        color: #ed1b2f;
    }

    .arrow {
        padding-left: 0 !important;
    }

    .arrow li:before {
        background: #179f96;
        content: "";
        display: block;
        height: 0.5625rem;
        left: 0;
        position: absolute;
        top: 0.5rem;
        width: 0.5625rem;
    }

    h3 {
        font-size: 1.75rem !important;
        line-height: 2.25rem !important;
        margin-bottom: 1.5rem !important;
    }

    .swicaUl li {
        line-height: 1.5rem;
        list-style-type: none;
        margin-top: 0.375rem;
        padding-left: 1.3125rem;
        position: relative;
    }

    .swicaUl a {
        color: #179f96;
        cursor: pointer;
        text-decoration: none;
    }

    .swicaUl a:hover {
        color: #15444c;
    }

    .rte-button:hover {
        color: #15444c;
    }

    .sympanyAccItem {
        border: none !important;
        border-bottom: 1px solid #ebd7c1 !important;
    }

    .sympanyAccItem .accordion-button:not(.collapsed) {
        background-color: transparent;
        color: #252129;
    }

    .benefitsSpan {
        color: #179f96;
    }

    .swicaAccItem {
        border: none !important;
        border-bottom: 1px solid #eee !important;
    }

    .swicaAccItem .accordion-button:not(.collapsed) {
        background-color: #179f96;
        color: #fff;
    }

    .swicaAccItem .accordion-button:not(.collapsed) .benefitsSpan {
        color: #fff;
    }

    .swicaAccItem .accordion-button:not(.collapsed) .swicaAccSvg path {
        stroke: #fff;

    }

    .benefitsDiv:hover {
        background-color: #179f96;
        color: #fff;
    }

    .swicaGreenBg {
        background: #179F96;
        border-radius: 4px 0px 0px 4px;
        color: #fff;
        height: 100%;
    }

    

    @media (max-width: 1399.98px) {}

    @media (max-width: 1199.98px) {}

    @media (max-width: 991.98px) {}

    @media (max-width: 767.98px) {
        .backgroundimageofgroupemuetel {
            height: 311px;
            background-repeat: no-repeat;
            border-radius: 0px 0px 20px 19px;
            width: 100%;
            background-position-x: 50%;
        }

        .swicaLogoDiv {
            position: absolute;
            top: 5rem;
            left: calc(50% - 143.5px);
        }
    }

    @media (max-width: 575.98px) {

        .bodyCounterPadding .finanuSubTitle {
            font-size: 20px !important;
        }



        .swicaLogoDiv {
            position: absolute;
            top: 6.5rem;
            left: calc(50% - 100px);
        }

        .swicaLogoDiv img {
            width: 200px;
        }
    }

    .DarkMode .sympanyAccItem {
        background-color: #333436;
        color: #fff;

    }
    .darkTheme .versicherungenLinks {
        background-color: #333436;
        box-shadow: unset;
    }